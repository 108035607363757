import { render, staticRenderFns } from "./Trust-marks.vue?vue&type=template&id=762536aa&"
var script = {}
import style0 from "./Trust-marks.vue?vue&type=style&index=0&id=762536aa&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports