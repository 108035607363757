
import lateStageCta from "~/components/Late-stage-cta.vue";
import trustMarks from "~/components/Trust-marks.vue";
import birdeye from "~/components/Birdeye.vue";

export default {
  head() {
    return {
      title: "Friendly Financial Services & Advisors",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "We are a very friendly Financial Services company in Epsom and Ewell, Surrey. Over the last 30 years we have become the trusted advisers to many clients across a wide range of financial planning needs.",
        },
      ],
    };
  },
  components: {
    trustMarks,
    lateStageCta,
    birdeye,
  },
};
